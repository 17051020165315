ul.smothscroll {
  position: fixed;
  bottom: 25px;
  right: 30px;
  list-style: none;
  z-index: 99;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 20px #fff;
}
ul.smothscroll a {
  background-color: #25d366;
  width: 50px;
  height: 50px;
  right: 30px;
  font-size: 30px;
  line-height: 45px;
  border-radius: 50%;
  display: block;
  text-align: center;
  color: #fff;
  background-size: 200%, 1px;
  background-position: 0;
  border: 2px solid white;
}
ul.smothscroll a:hover {
  background-color: #592c87;
  color: #fff;
}

@media (max-width: 767px) {
  ul.smothscroll a {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 30px;
    line-height: 45px;
  }
}

ul.brochure {
  position: fixed;
  bottom: 100px;
  right: 30px;

  /* list-style: none; */
  z-index: 99;
  padding: 10px 10px;
  background-color: #592c87;
  border-radius: 100%;
  border: 2px solid #fff;
  box-shadow: 20px #fff;
}
#downloadIcon {
  width: 20px !important;
  /* font-size: 40px !important; */
  /* padding: 5px; */
}
#item {
  width: 10px;
}

ul.brochure a {
  padding-left: 3px;
  background-color: #592c87;
  width: 20px;
  /* height: 50px; */

  /* writing-mode: vertical-rl; */
  /* font-size: 10px; */
  word-wrap: break-word;
  line-height: 20px;
  border-radius: 50%;
  display: block;
  text-align: center;
  color: #fff;
  background-size: 200%, 1px;

  background-position: 0;
  /* border: 2px solid white; */
}
ul.brochure a:hover {
  background-color: #592c87;
  color: #fff;
}

/* @media (max-width: 767px) {
  ul.brochure {
    position: fixed;
    bottom: 250px;
    padding-left: 3px;
    right: 0px;
    list-style: none;
    z-index: 99;
    right: 30px;
    padding: 10px 8px;
    background-color: #592c87;
    
    border: 2px solid #fff;
    box-shadow: 20px #fff;
  }
  ul.brochure a {
    width: 10px;
 
    text-align: center;
    font-size: 15px;
    line-height: 15px;
  }
} */
