/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800&family=Noto+Kufi+Arabic:wght@300;400;500;600;700&family=Sail&display=swap");

// fonts
$base-font-size: 15;
$base-font: "Jost", sans-serif;
$heading-font: "Sail", cursive;

// color
$dark-gray: #fff;
$body-color: #5c5c5c;
$white: #fff;
$light: #000;
$black: #000;
$small-black: #333;
$cyan: #fff;

// $theme-primary-color: #657150;
$theme-primary-color: #592c87;
$theme-primary-color-s2: #592c87;
$theme-primary-color-s3: #592c87;
$theme-primary-color-s4: #e5d1c7;
$body-bg-color: #fff;
$section-bg-color: #9370db;
$section-bg-color-s2: #9370db;
$section-bg-color-s3: #9370db;
$section-bg-color-s4: #9370db;
$text-color: #fff;
$text-light-color: #7b7b7b;
$heading-color: $dark-gray;
$heading-color2: #3f3f3f;
$border-color: #f3ece9;
$border-color-s2: #e4ebf2;
